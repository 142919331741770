export const menuItems = [
  {
    text: "Home",
    href: "/",
    type: "link",
  },
  {
    text: "Upload",
    href: "/upload",
    type: "link",
  },
  {
    text: "Create",
    type: "dropdown",
    options: [
      {
        text: "Create Category",
        href: "/category/create",
        type: "link",
      },
      {
        text: "Create Project",
        href: "/project/create",
        type: "link",
      },
      {
        text: "Create Build",
        href: "/build/create",
        type: "link",
      },
      {
        text: "Create Build Config (RF)",
        href: "/build_config/create",
        type: "link",
      },
      {
        text: "Create ACE",
        href: "/ace/create",
        type: "link",
      },
      {
        text: "Create Testplan",
        type: "subdropdown",
        options: [
          { text: "RF", href: "/testplan/rf/create" },
          { text: "EMI", href: "/testplan/emi/create" },
        ],
      },
    ],
  },
  {
    text: "Tools",
    type: "dropdown",
    options: [
      {
        text: "Report History",
        href: "/reports/history",
      },
    ],
  },
  // {
  //   text: "Project Management",
  //   type: "dropdown",
  //   options: [
  //     {
  //       text: "Edit Build",
  //       href: "/build/edit",
  //       type: "link",
  //     },
  //   ],
  // },
];

export const helpItem = {
  text: "Help & Support",
  type: "dropdown",
  options: [
    {
      text: "Report Bug",
      href: "https://issues.labcollab.net/browse/LENS-8083",
      useATag: true,
      newTab: true,
    },
    {
      text: "Request Feature",
      href: "https://issues.amazon.com/issues/create?template=bf301c2c-bffb-4c6f-b115-3a301cb85a01",
      useATag: true,
      newTab: true,
    },
    {
      text: "About Us",
      href: "/about-us",
    },
  ],
};

export const analyticsItem = {
  text: "Analytics",
  href: "/analytics",
};
